import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import { Timestamp as FBTimestamp } from "firebase/firestore";

export interface IFirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

type envName = "production" | "development" | "staging" | "testing";

const ENV_CONFIG = {
  production: {
    apiKey: "AIzaSyDmrnVp5LxyBbhcf8NmnIrlBOfBoKmpr04",
    authDomain: "seenspire-v3-production.firebaseapp.com",
    databaseURL: "https://seenspire-v3-production.firebaseio.com",
    projectId: "seenspire-v3-production",
    storageBucket: "seenspire-v3-production.appspot.com",
    messagingSenderId: "9549505783",
    appId: "1:9549505783:web:db33caeb10503fdd079ff2",
    presentationBaseUrl: "https://content.seenspire.com",
  },
  staging: {
    apiKey: "AIzaSyAcOxnTOyIfEv7YagmkAXiy6RO7OLIlCNA",
    authDomain: "seenspire-v3-staging.firebaseapp.com",
    databaseURL: "https://seenspire-v3-staging.firebaseio.com",
    projectId: "seenspire-v3-staging",
    storageBucket: "seenspire-v3-staging.appspot.com",
    messagingSenderId: "383131090732",
    appId: "1:383131090732:web:b0d672cdd802b558027689",
    presentationBaseUrl: "https://staging-content.seenspire.com",
  },
  testing: {
    apiKey: "AIzaSyAcOxnTOyIfEv7YagmkAXiy6RO7OLIlCNA",
    authDomain: "seenspire-v3-staging.firebaseapp.com",
    databaseURL: "https://seenspire-v3-staging.firebaseio.com",
    projectId: "seenspire-v3-staging",
    storageBucket: "seenspire-v3-staging.appspot.com",
    messagingSenderId: "383131090732",
    appId: "1:383131090732:web:b0d672cdd802b558027689",
    presentationBaseUrl: "https://staging-content.seenspire.com",
  },
  development: {
    apiKey: "AIzaSyAcOxnTOyIfEv7YagmkAXiy6RO7OLIlCNA",
    authDomain: "seenspire-v3-staging.firebaseapp.com",
    databaseURL: "https://seenspire-v3-staging.firebaseio.com",
    projectId: "seenspire-v3-staging",
    storageBucket: "seenspire-v3-staging.appspot.com",
    messagingSenderId: "383131090732",
    appId: "1:383131090732:web:b0d672cdd802b558027689",
    presentationBaseUrl: "https://staging-content.seenspire.com",
  },
};

const target = (process.env.REACT_APP_STAGE as envName) || "testing";

const firebaseConfig: IFirebaseConfig = ENV_CONFIG[target];

const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

export default app;

let authInstance;
let dbInstance;
let rtdbInstance;
let analyticsInstance;

try {
  authInstance = app.auth();
  dbInstance = app.firestore();
  rtdbInstance = app.database();
  analyticsInstance = firebase.analytics();
} catch (error) {
  console.log("Firebase Error: ", error);
}

export const auth = authInstance as unknown as firebase.auth.Auth | undefined;
export const db = dbInstance as unknown as firebase.firestore.Firestore | undefined;
export const rtdb = rtdbInstance as unknown as firebase.database.Database | undefined;
export const analytics = analyticsInstance as unknown as firebase.analytics.Analytics | undefined;

export type Timestamp = FBTimestamp;
